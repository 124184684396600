import { useEffect, useMemo, useState } from "react";
import { Box, IconButton, TableCell, Tooltip, Typography } from "@mui/material";

import MUIDataTable from "mui-datatables";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileInvoice, faPencil } from "@fortawesome/free-solid-svg-icons";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import DetailsDialog from "../../../components/DetailsDialog";
import OrderDetail from "../orders/OrderDetail";

export default function ProductDetailsDataTable(props)
{
    const [details,setDetails] = useState(props.details);
    const memoizedDetails = useMemo(() => props.details, [props.details]);

    const [selectedOrder,setSelectedOrder] = useState(null);
    const [type,setType] = useState('');

    const [isOpen,setIsOpen] = useState(false);

    const history = useHistory();
    const redirect = (route) => {
        history.push(route)
    }

    const columns = [
        {
            name:'order_id',
            options:{
                display: 'excluded',
                filter:false
            }
        },
        {
            name:'order_type',
            options:{
                display: 'excluded',
                filter:false
            }
        },
        {
            name:'date',
            label:<Typography>Fecha</Typography>,
            options:{
                filter: false
            }
        },
        {
            name:'matadero',
            label:<Typography>Matadero</Typography>,
            options:{
                customHeadRender:()=>{
                    return(
                        <TableCell align="right">
                            <Typography>Matadero</Typography>
                        </TableCell>
                    )
                },
                customBodyRenderLite : (dataIndex) => {
                    //let id = subCategorias[dataIndex].idSubCategoria;
                    return(
                        <Typography textAlign={'right'}>
                            {memoizedDetails[dataIndex].matadero}
                        </Typography>
                    );
                },
                filter: false,
            }
        },
        {
            name:'ff',
            label:<Typography>FF</Typography>,
            options:{
                customHeadRender:()=>{
                    return(
                        <TableCell align="right">
                            <Typography>FF</Typography>
                        </TableCell>
                    )
                },
                customBodyRenderLite : (dataIndex) => {
                    //let id = subCategorias[dataIndex].idSubCategoria;
                    return(
                        <Typography textAlign={'right'}>
                            {memoizedDetails[dataIndex].ff}
                        </Typography>
                    );
                },
                filter: false,
            }
        },
        {
            name:'unit_price',
            label:<Typography>Precio</Typography>,
            options:{
                customHeadRender:()=>{
                    return(
                        <TableCell align="right">
                            <Typography>Precio $</Typography>
                        </TableCell>
                    )
                },
                customBodyRenderLite : (dataIndex) => {
                    //let id = subCategorias[dataIndex].idSubCategoria;
                    return(
                        <Typography textAlign={'right'}>
                            {memoizedDetails[dataIndex].unit_price}
                        </Typography>
                    );
                },
                filter: false,
            }
        },
        {
            name:'total',
            label:<Typography>Total</Typography>,
            options:{
                customHeadRender:()=>{
                    return(
                        <TableCell align="right">
                            <Typography>Total $</Typography>
                        </TableCell>
                    )
                },
                customBodyRenderLite : (dataIndex) => {
                    //let id = subCategorias[dataIndex].idSubCategoria;
                    return(
                        <Typography textAlign={'right'}>
                            {memoizedDetails[dataIndex].total}
                        </Typography>
                    );
                },
                filter: false,
            }
        },
        {
            name:'type',
            label:<Typography>Tipo</Typography>,
            options:{
                filter: true,
            }
        },
        /*{
            name:'balance',
            label:<Typography>Saldo</Typography>,
            options:{
                customHeadRender:()=>{
                    return(
                        <TableCell align="right">
                            <Typography>Saldo</Typography>
                        </TableCell>
                    )
                },
                customBodyRenderLite : (dataIndex) => {
                    //let id = subCategorias[dataIndex].idSubCategoria;
                    return(
                        <Typography textAlign={'right'}>
                            {memoizedDetails[dataIndex].balance}
                        </Typography>
                    );
                },
                filter: false,
            }
        },*/
        {
            name:'entity',
            label:<Typography>Proveedor/Cliente</Typography>,
            options:{
                
                filter: false,
            }
        },
        {
            name:'',
            label:<Typography>Detalles</Typography>,
            options:{
                customBodyRenderLite : (dataIndex) => {
                    //let id = subCategorias[dataIndex].idSubCategoria;
                    return(
                        <Typography textAlign={'left'}>
                            #{memoizedDetails[dataIndex].order_number} - {memoizedDetails[dataIndex].notes}
                        </Typography>
                    );
                },
                filter: false,
            }
        },
    ];

    const handleSelectedOrder = (id) => {
        setSelectedOrder(id);
        setIsOpen(true);
    }

    const handleType = () => {
        if(type === 'inbound')
            return 'Entrada';
        else
            return 'Salida';
    }

    const options = {
        filterType: 'checkbox',
        selectableRows: 'none',
        onRowClick: (rowData,rowMeta) => {
            console.log('DATA INDEX ===> ',rowData[0])
            setType(rowData[1]);
            handleSelectedOrder(rowData[0]);

        } 
    };

    return(
        <>
            <MUIDataTable
                title={<Typography>Detalles de {details[0].product}</Typography>}
                data={memoizedDetails}
                columns={columns}
                options={options}
            />
            <DetailsDialog
                open={isOpen}
                title={`Orden de ${handleType()} #${selectedOrder}`}
                subtitle={'(Detalles)'}
                handleClose={()=>setIsOpen(false)}
            >
                <OrderDetail type={type} id={selectedOrder}/>
            </DetailsDialog>
        </>
    )
}